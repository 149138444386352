import { handleErrorLog } from "./error_logs";

export const updateImageURL = (
  imgURL,
  width = 308,
  height = 280,
  isBanner = false,
) => {
  if (!imgURL) return "";
  try {
    const urlSchema = new URL(imgURL);
    urlSchema.searchParams.set("auto", "webp");
    if (urlSchema.searchParams.has("height")) {
      urlSchema.searchParams.set("height", height);
    }
    if (urlSchema.searchParams.has("width")) {
      urlSchema.searchParams.set("width", width);
    }

    if (isBanner) {
      urlSchema.searchParams.delete("auto");
      urlSchema.searchParams.set("w", width);
      urlSchema.searchParams.set("h", height);
      urlSchema.searchParams.set("fm", "webp");
    }
    return urlSchema.toString();
  } catch (error) {
    handleErrorLog({
      error,
      additional_info: { imgURL, width, height, isBanner },
      msg: "Error while updating image url for optimization",
    });
    return imgURL;
  }
};

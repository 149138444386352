"use client";
import { unsubscribeNotifyStockAlertMailGuest } from "@/lib/services/product";
import { handleToastError, handleToastSuccess } from "@/lib/utils";
import { useTranslation } from "@/i18n/client";
import { useEffect } from "react";

const UnSubscribeStockAlert = ({
  searchParams: { pi: product_id, ue: email, ...restParams },
}) => {
  const { t } = useTranslation(["common"]);
  useEffect(() => {
    const unSubscribeNotifyStock = async () => {
      try {
        const { data, error } = await unsubscribeNotifyStockAlertMailGuest({
          product_id,
          email,
        });
        if (error) {
          handleToastError(error);
        } else {
          handleToastSuccess(
            t("common:messages.unsubscribedStock", {
              email: data?.unSubscribeGuestProductAlertStock?.email,
            }),
          );
        }
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}?${new URLSearchParams(restParams)}`,
        );
      } catch (error) {
        handleToastError(error.message || error);
      }
    };
    unSubscribeNotifyStock();
  }, []);
  return null;
};

export default UnSubscribeStockAlert;

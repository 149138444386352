import Link from "next/link";
import { constants } from "@/lib/utils/constants";
import ImageWithFallback from "../ImageWithFallback";
import ArrowRight from "@/assets/icon/arrow_right_alt.svg?url";
import styles from "@/assets/css/pages/font.module.css";
export default function CategoryCard({ heading, description, image, href }) {
  return (
    <Link prefetch={false} href={href} className="category-card-section">
      <div className="xs:p-5 xs:pt-[28px] xs:pb-12 p-3 text-left">
        <div className="flex justify-between items-center relative">
          <h3 className={`${styles.heading20} xs:text-primary-black text-primary-black-700 mb-1 z-10 relative`}>
            {heading}
          </h3>
          <ImageWithFallback
            width={24}
            height={24}
            unoptimized
            className="icon"
            src={ArrowRight}
            alt="arrow-right"
          />
        </div>
        <p className="category-card-content max-w-[95%] z-10 relative">
          {description}
        </p>
      </div>
      <div className="flex justify-start items-end">
        <div className="flex h-full xs:max-h-[149px] w-12/12">
          <ImageWithFallback
            src={image} // Default image
            width={117}
            height={92}
            quality={95}
            alt={heading}
            type={constants.image_types.category}
            className="w-[117px] xs:w-12/12 xs:max-w-[152px] xs:h-[149px] h-[117px] object-contain z-10 relative"
          />
        </div>
      </div>
    </Link>
  );
}

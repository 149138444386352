import { routes } from "@/lib/utils/routes";
import { showVehicleInfo } from "@/lib/utils/functions";
import Input from "@/components/common/shared/Input/input";
import Button from "@/components/common/shared/Button/button";

const VehicleInfoBox = ({ t, vehicle, handleChangeVehicle }) => {
  return (
    <div className="selectValueBox">
      <div className="findByRegistrationBox export">
        <div className="flex justify-between xs:flex-row flex-col">
          <div className="inputBox">
            <Input
              disabled
              type="text"
              name="vehicle"
              id="vehicle-info-input"
              placeholder={showVehicleInfo(vehicle)}
            />
            <Button
              theme="blue-underlined"
              onClick={handleChangeVehicle}
              className="absolute -translate-y-1/2 top-6/12 right-[30px] text-primary-blue-900 max-w-max"
            >
              {t("current_vehicle.change_vehicle")}
            </Button>
          </div>
          <Button
            type="link"
            prefetch={false}
            theme="green-btn"
            href={routes.parts}
          >
            {t("current_vehicle.exploreParts")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VehicleInfoBox;

"use client";

import Cookies from "js-cookie";
import { Fragment } from "react";
import { useAppDispatch } from "@/lib/store";
import VehicleInfoBox from "./VehicleInfoBox";
import { updateVehicle } from "@/lib/store/reducers/vehicle";
import Button from "@/components/common/shared/Button/button";

const VehicleDetail = ({ t, vehicle, cookieKeys }) => {
  const dispatch = useAppDispatch();
  const handleChangeVehicle = () => {
    dispatch(updateVehicle(null));
    Cookies.remove(cookieKeys.new_vehicle);
    Cookies.remove(cookieKeys.manual_lookup);
    Cookies.remove(cookieKeys.selectedVehicle);
  };

  return (
    <Fragment>
      <div className="btnBox details">
        <Button icon="carBlue" iconPosition="left" theme="white-secondary-btn">
          {t("vehicle.yourVehicle")}
        </Button>
      </div>

      <VehicleInfoBox
        t={t}
        vehicle={vehicle}
        handleChangeVehicle={handleChangeVehicle}
      />
    </Fragment>
  );
};

export default VehicleDetail;

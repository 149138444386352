"use client";

import { useEffect, useState } from "react";
import { useAppSelector } from "@/lib/store";
import { useTranslation } from "@/i18n/client";
import VehicleForm from "./VehicleForm";
import VehicleDetail from "./VehicleDetail";

const CarRegistrationBox = ({ cookieKeys }) => {
  const { t } = useTranslation(["garage", "common"]);
  const { vehicle } = useAppSelector((state) => state.vehicle);
  const [vehicleInfo, setVehicleInfo] = useState(null);

  useEffect(() => {
    setVehicleInfo(vehicle);
  }, [vehicle]);

  return (
    <div
      className={`carRegistrationBox ${
        vehicleInfo ? "slide-in-top" : "slide-in-bottom"
      } `}
    >
      {vehicleInfo ? (
        <VehicleDetail t={t} vehicle={vehicleInfo} cookieKeys={cookieKeys} />
      ) : (
        <VehicleForm t={t} />
      )}
    </div>
  );
};

export default CarRegistrationBox;
"use client";

import ReviewSlider from "./ReviewSlider";
import { useEffect, useState } from "react";
import Stars from "@/components/common/Stars";
import { useTranslation } from "@/i18n/client";
import { fetchReviews } from "@/lib/services/general";
import styles from "@/assets/css/pages/font.module.css";
import { handleErrorLog } from "@/lib/utils/error_logs";

const ReviewSection = () => {
  const { t } = useTranslation(["home"]);
  const [data, setData] = useState({ loading: true });

  useEffect(() => {
    const fetchReviewsInfo = async () => {
      try {
        const res = await fetchReviews();
        setData(() => {
          return {
            loading: false,
            ...res,
          };
        });
      } catch (err) {
        handleErrorLog({
          error: err,
          is_warning: true,
          msg: "error while fetching reviews:::",
        });
      }
    };
    fetchReviewsInfo();
  }, []);

  if (data.loading) {
    return (
      <section className="bg-secondary-500 lg:py-[80px] h-[400px] xs:h-[625px] xs:py-11 pt-9 pb-24 comments-section animation-fadeIn"></section>
    );
  }
  const { reviews = [], review_count, average_rating = 0 } = data;
  return (
    <section className="bg-secondary-500 lg:py-[80px] xs:py-11 pt-9 pb-24 comments-section animation-fadeIn xs:min-h-max min-h-[400px]">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bg-secondary-500">
              <div className="flex flex-col items-center justify-center">
                <h3 className={`${styles.heading32B2} xs:mb-3 mb-[2px]`}>
                  {t("reviews.heading")}
                </h3>
                <div className="review-sub-heading">
                  <span className="lg:text-body-text-1-xb xs:text-body-text-2-b text-minor-text-1-b">
                    {t("reviews.text.rating")}
                  </span>{" "}
                  <Stars rating={+average_rating} />
                  <span className="font-bold">{average_rating}</span>{" "}
                  {t("reviews.text.based_on")}{" "}
                  <span className="font-bold">
                    {review_count?.toLocaleString()}
                  </span>{" "}
                  {t("reviews.text.reviews")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReviewSlider reviews={reviews} className="mb-0" />
    </section>
  );
};

export default ReviewSection;

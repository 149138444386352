"use client";

import Link from "next/link";
import Image, { getImageProps } from "next/image";
import { updateImageURL } from "@/lib/utils/images";
import { getContentfulImgData } from "@/lib/services/contentful";
import CarRegistrationBox from "@/components/common/shared/CarRegistration";
import WhitePlaceholderMbl from "@/assets/static/placeholder-white-mbl.png";
import WhitePlaceholderDesktop from "@/assets/static/placeholder-white-deskop.png";

const HomeBanner = ({ banner, cookieKeys, bannerImageForMobile }) => {
  const bannerLink = banner?.fields?.link;
  const desktopImg = getContentfulImgData(banner?.fields?.image);
  const mobileImg = getContentfulImgData(bannerImageForMobile?.fields?.image);

  const {
    props: { srcSet: desktopSrcSet, src: desktopSrc },
  } = getImageProps({
    loading: "eager",
    alt: desktopImg?.title || desktopImg?.fileName || "Home Banner",
    width: 1440,
    height: 440,
    quality: 100,
    src: updateImageURL(desktopImg?.imageUrl, 1440, 440, true),
  });

  // Mobile Image Data
  const {
    props: { srcSet: mobileSrcSet, src: mobileSrc, ...rest },
  } = getImageProps({
    loading: "eager",
    alt: mobileImg?.title || mobileImg?.fileName || "Home Banner",
    width: 375,
    height: 200,
    quality: 100,
    src: updateImageURL(mobileImg?.imageUrl, 767, 380, true),
  });

  const PictureTag = (
    <picture>
      <source
        media="(min-width: 520px)"
        srcSet={desktopImg ? desktopSrcSet : WhitePlaceholderDesktop.src}
      />
      <source
        media="(max-width: 519px)"
        srcSet={mobileImg ? mobileSrcSet : WhitePlaceholderMbl.src}
      />
      <Image
        {...rest}
        priority
        alt={rest.alt}
        className="w-12/12 xs:order-1 order-2"
        style={{ width: "100%", height: "auto" }}
        onError={() => console.error("Image failed to load")}
        src={desktopImg ? desktopSrc : WhitePlaceholderDesktop.src}
      />
    </picture>
  );

  return (
    <section className="w-12/12 relative flex flex-col xs:pb-[65px] pb-24 max-w-[1440px] mx-auto z-[2] xs:min-h-max min-h-[467px]">
      <CarRegistrationBox cookieKeys={cookieKeys} />
      {bannerLink ? (
        <Link
          rel="preload"
          prefetch={false}
          href={bannerLink}
          className="home-banner-section"
        >
          {PictureTag}
        </Link>
      ) : (
        { PictureTag }
      )}
    </section>
  );
};

export default HomeBanner;

"use client";

import Image from "next/image";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { viewItemListAnalytics } from "@/lib/gtm";
import { useSearchParams } from "next/navigation";
import { sortProducts } from "@/lib/utils/helpers/home";
import { handleErrorLog } from "@/lib/utils/error_logs";
import { getSeasonalProducts } from "@/lib/services/home";
import { createItemListPayload } from "@/lib/utils/helpers/plp";
import SkeletonImg from "@/assets/static/seasonal_placeholder.svg?url";
import { formatProductGA4, mutateProductInfo } from "@/lib/utils/functions";

const SeasonalProductList = dynamic(() => import("./SeasonalProductList"));

const SeasonalProduct = ({
  seasonal,
  cookieKeys,
  headings = {},
  bestSellerImg = "",
  recommendedImg = "",
  seasonalImageForMobile,
}) => {
  const searchParams = useSearchParams();
  const [fetching, setFetching] = useState(true);
  const [products, setProducts] = useState({ items: [], count: 0 });

  useEffect(() => {
    const sendSeasonalAnalytics = (list) => {
      if (list?.count > 0) {
        // View cart GA4 for Seasonal viewed
        const formattedProducts = formatProductGA4(
          list.items
            .map((p) => mutateProductInfo(p))
            .map((el) => ({ ...el, quantity: el.extraVariable.qty })),
        );

        viewItemListAnalytics(
          createItemListPayload(formattedProducts, "Seasonal Products", true),
        );
      }
    };

    const fetchSeasonalProducts = async () => {
      try {
        const scCookie = Cookies.get(cookieKeys.specialCoupon);
        const autoApplyCoupon = searchParams.get("auto_apply_coupon");
        const specialCoupon = autoApplyCoupon || scCookie || null;
        const [res, apiErr] = await getSeasonalProducts({ specialCoupon });
        if (apiErr) {
          handleErrorLog({
            error: apiErr,
            is_warning: true,
            msg: "seasonal products api:::",
          });
          return;
        }

        if (res?.seasonal_products) {
          const productList = {
            count: res.seasonal_products?.total_count,
            items: sortProducts(res.seasonal_products),
          };
          setProducts(productList);
          sendSeasonalAnalytics(productList);
          setFetching(false);
        }
      } catch (err) {
        handleErrorLog({
          error: err,
          is_warning: true,
          msg: "error while fetching seasonal products:::",
        });
        setFetching(false);
      }
    };
    fetchSeasonalProducts();
  }, []);

  if (!fetching) {
    if (!products?.count) return null;
    return (
      <SeasonalProductList
        desktop={seasonal}
        products={products}
        bestSellerImg={bestSellerImg}
        recommendedImg={recommendedImg}
        mobile={seasonalImageForMobile}
        title={headings?.seasonal_product_title}
        description={headings?.seasonal_product_desc}
      />
    );
  }

  return (
    <section className="seasonSection animation-fadeIn xs:min-h-max min-h-[361px]">
      <div className="container">
        <div className="row bg-tertiary-white">
          <Image
            width={1440}
            height="auto"
            src={SkeletonImg}
            alt={headings.seasonal_product_title}
            className="w-[450] xs:w-[1440] animate-pulse"
          />
        </div>
      </div>
    </section>
  );
};

export default SeasonalProduct;
